.container {
  margin: 0 auto;
  padding-top: 5px;
  /* min-height: calc(100vh - 100px); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  overflow: hidden;
}

.container .content {
  /* position: absolute; */
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  color: #f1f1f1;
  width: 100%;
  flex: 1;
  padding-bottom: 0px;
  /* padding-top: 50px; */
}

.backgroundImage {
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-bottom: 0;
  /* width: %; */
  height: 320px;
  background-size: cover;
  background-position: center;
}

.heading {
  font-family: 'Exo', sans-serif;
  text-align: center;
  font-size: 20px;
  color: #437dcb;
}

.description {
  text-align: justify;
  padding-left: 40px;
  padding-right: 40px;
  color: #a1bee5;
  margin-bottom: 0px;
}

.button {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  color: #437dcb;
  border: 5px solid #977322;
  background-color: black;
  margin: 25px 5px 0px 5px;
  padding: 10px;
  cursor: pointer;
  width: 100px;
  border-radius: 10px;
}

.center {
  display: flex;
  justify-content: center;
}

.liveButton {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  color: #437dcb;
  border: 5px solid #977322;
  background-color: #d88e31;
  margin: 25px 5px 0px 5px;
  padding: 10px;
  cursor: pointer;
  width: 100px;
  border-radius: 10px;
}

.liveButton:hover {
  color: #fafafa;
}

.gitButton {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  color: #437dcb;
  border: 5px solid #977322;
  background-color: black;
  margin: 25px 5px 0px 5px;
  padding: 10px;
  cursor: pointer;
  width: 100px;
  border-radius: 10px;
}
.gitButton:hover {
  color: #fafafa;
}
a {
  text-align: center;
  color: #437dcb;
  font-weight: 500;
}

a:hover {
  color: #d88e31;
}
@media only screen and (min-width: 768px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .content {
    /* height: 230px; */
    padding-bottom: 50px;
  }
  .description {
    text-align: center;
  }

  .backgroundImage {
    /* width: 100%; */
    height: 768px;
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (min-width: 992px) {
  .description {
    text-align: center;
    max-width: 1070px;
    margin: 0 auto;
  }

  .container {
    /* position: relative; */
    padding: 0px;
    /* min-width: 1261px; */
    /* height: calc(130vh - 100px); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .backgroundImage {
    border: 0;
    /* width: 100%; */
    /* height: 1200px; */
    background-size: cover;
    background-position: center;
  }

  .content {
    /* height: 430px; */
    padding-bottom: 50px;
  }
}
