.product {
  /* the width of each screen shot */
  width: 90%;
  height: 40vh;
  margin: 10px 10px 0px 10px;
  /* border: 2px solid rgb(243, 242, 242); */
  border: 2px solid #fafafa;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
}

.productBrowser {
  display: flex;
  align-items: center;
  /* height: 20px; */
  background-color: rgb(243, 242, 242);
  /* background-color: #fafafa; */
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  /* background-color: grey; */
}

.productImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.overLay {
  position: absolute;
  top: -10px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  /* background-color: rgb(243, 242, 242); */
  /* background-color: #fafafa; */
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px 10px 10px 10px;
  z-index: 2;
}

.container:hover .overLay {
  opacity: 1;
}

.container img {
  transform-origin: 50% 50%;
  transition: transform 5s, filter 3s ease-in-out;
  /* filter: brightness(150%); */
}

.container:hover img {
  /* transform: scale(1.1); */
  /* filter: brightness(100%); */
  transform: scale(1.8);
}

.text {
  display: none;
}

.button {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  color: #437dcb;
  border: 5px solid #437dcb;
  /* background-color: rgb(243, 242, 242);  */
  background-color: #d88e31;
  margin: 0px;
  padding: 14px 28px;
  cursor: pointer;
  border-radius: 10px;
}

.button {
  border-color: #977322;
  color: #3d79c9;
}
.button:hover {
  /* background: #d88e31; */
  color: #fafafa;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .product {
    width: 90%;
    height: 50vh;
    margin: 10px 10px;
  }
  .overLay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) {
  .overLay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product {
    width: 45%;
    height: 40vh;
    margin: 10px 10px;
  }
}

@media screen and (max-width: 600px) {
  .overLay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
