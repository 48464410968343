.footer {
  font-family: 'Exo', sans-serif;
  font-weight: 550;
  padding: 1rem;
  bottom: 0;
  left: 0;
}

.social {
  text-align: center;
  padding-left: 10px;
}
.social ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social ul li {
  /* display: inline-block;
  padding-left: 10px;
  margin: 10px; */
  margin: 0 10px;

}

.link {
  /* margin-left: 15px;
  margin-right: 15px; */
  margin: 0 15px;

}

p {
  text-align: center;
  color: #437dcb;
}

a {
  font-weight: 550;
}

.socialIcon {
  width:23px;
  height:24px;
  font-weight: 550;
  background-color: #437dcb;
  padding: 2px;
  border-radius: 3px;
}

.socialIcon:hover {
  background-color: #d78c30;
}
@media only screen and (max-width: 320px) {
  .footer {
    display: none;
  }
}
