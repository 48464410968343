.error  {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
 
}

.link{
  display: flex;
  align-items: center;
  justify-content: center;
}

