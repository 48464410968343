.container {
  /* display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 200px 200px 200px; */

  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

iframe {
  /* display: block; 
  background: black;
  border: none;
  height: 100%; 
  width: 100%; 
  border: solid 5px #437dcb; 
  border-radius: 30px; */
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.heading {
  font-family: 'Exo', 'sans-serif';
  text-align: center;
  color: #437dcb;
  font-size: 40px;
  margin-top: 20px;
}
