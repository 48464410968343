.aboutContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 20px;
}

/* .aboutCardBackground {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
} */

.aboutCard {
  width: 100%;
  height: 30vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  margin-right: 0px;
  margin-left: 0px;
  border: 5px solid #977322;
}

.aboutImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: url('my-image.avif');
}

/* .aboutTitle {
  font-weight: 700;
  font-family: 'Exo', sans-serif;
  font-size: 40px;
  color: #437dcb;
  margin-bottom: 20px;
} */

.aboutSubHeading {
  margin: 20px 0px;
}

.aboutDescription {
  /* font-weight: 500; */
  line-height: 1.5em;
  color: #a1bee5;
  margin-bottom: 0px;
  max-width: 1070px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.aboutLeft {
  /* margin-top:20px; */
}
.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.buttonContainer button {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-size: 16px;
  font-weight: 500;
  border: 5.5px solid #977323;
  color: #437dcb;
  height: 80px;
  width: 180px;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px auto;
  margin-left: 20px;
  padding: 0px;
}

.contactButton {
  background-color: #d78c30;
  
}

.contactButton:hover {
  color: #fafafa;
}

.workButton {
  background-color: black;
}

.workButton:hover {
  color: #fafafa;
}
@media only screen and (min-width: 992px) {
  .aboutContainer {
    display: flex;
    /* CHANGE THIS TO DISPLAY IMAGE ABOVE TEXT */
    flex-direction: column;
    /* align-items: center; */
    margin: 0 auto;
    max-width: 1048px;
  }

  /* .aboutLeft {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 50px;
    margin-top: 0px;
  } */

  .aboutLeft {
    margin-top: 0px;
  }
  .aboutRight {
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .aboutTitle {
    padding-left: 50px;
    margin-top: 0px;
  }

  .aboutDescription {
    font-size: 20px;
    text-align: justify;
  }

  /* .aboutCard {
     margin: 0px 0px 0px 0px; 
  } */
}

@media only screen and (min-width: 768px) {
  .aboutCard {
    margin-top: 0px;
  }

  .aboutLeft {
    margin-top: 100px;
  }
  .aboutDescription {
    padding-right: 50px;
    padding-left: 50px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .aboutLeft {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .aboutLeft {
    margin-top: 100px;
  }
}
