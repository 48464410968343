.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 50px;
}

.productListText {
  width: 65%;
}

.productListTitle {
  font-size: 40px;
  font-weight: 700;
  font-family: 'Exo', sans-serif;
  line-height: 3rem;
  margin-top: 20px;
  color: #437dcb;
}

.productListDescription {
  margin: 20px 0px;
  color: #a1bee5;
  font-weight: 500;
  line-height: 1.5em;
}

.productList {
  max-width: 1048px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

span {
  font-style: italic;
}
