.contactContainer {
  font-family: 'Exo', sans-serif;
  margin-bottom: 112px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 0px;
}

.contactTitle {
  font-family: 'Exo', sans-serif;
  font-size: 40px;
  color: #437dcb;
  text-align: center;
}

.contactEmail {
  
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  color: #437dcb;
  border: 5px solid #977322;
  /* background-color: ; */
  /* margin: 50px 10px 0px 0; */
  padding: 14px 28px;
  cursor: pointer;
  width: 150px;
  margin: 0 auto;
  margin-left:10px;
  margin-top: 30px;
  border-radius: 10px;
}

.contactEmail:hover {
  background: #d88e31;
  color: #3d79c9;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
