.heading {
  font-family: 'Exo', sans-serif;
  font-weight: 700;
  color: #437dcb;
  font-size: 20px;
  /* background-color: #a6c2e6; */
  /* padding: 10px; */
  cursor: pointer;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}


.heading:hover {
  color:#d78c30;
}

.navlink {
  display: flex;
  list-style: none;
}

.nav-item:not(:first-of-type) {
  margin-left: 1.5em;
}

.navlink {
  color: #fff;
  text-decoration: none;
  border-style: none;
  font-weight: 500;
}
.navlink li {
  border-style: none;
}

/* .navbar {
  width: 100vw;
  height: 7.5vh;
  color: #fff;
  padding: 0 5vw;
} */

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar img {
  width: 40px;
  height: 40px;
  background-color: black;
  opacity: 1;
}

@media only screen and (min-width: 992px) {
  .navbar img {
    margin-left: 10px;
  }
}
