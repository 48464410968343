.skillsContainer {
  max-width: 768px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.skillsTitle {
  font-size: 2.5rem;
  font-family: 'Exo', sans-serif;
  color: #437dcb;
  text-align: center;
  margin-bottom: 20px;
}

.skillsList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin-top: 20px;
}

/* .text {
  border: 2px solid #977322;
  font-weight: 500;
  padding: 2px;
  border-radius: 5px;
} */

.icon {
  width: 80px;
  height: 80px;
  /* padding-bottom: 10px; */
}

li {
  border: 2px solid #977322;
  padding: 0.5rem;
}

li {
  margin-right: 2px;
  transition: margin 0.2s ease-in-out;
}

li:hover .text {
  background-color: #d88e31;
  cursor: pointer;
  /* margin-top: -5px; */
  /* visibility: visible; */
}

.text:hover {
  background-color: red;
}
/* @media only screen and (min-width: 600px) {
  
} */
