.introContainer {
  display: flex;
  max-width: 1328px;
  margin: 0 auto;
  flex-direction: column;
  height: 50vh;
  padding-top: 20px;
  padding-bottom:0px;
}

.introLeft {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.introRight {
  flex: 1;
  position: relative;
  display: none;
}

.introLeftWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.introHeading {
  font-size: 30px;
  font-weight: 700;
  font-family: 'Exo', sans-serif;
  color: #437dcb;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 20px 0px;
}

.introName {
  font-size: 30px;
  color: #427bc8;
  font-family: 'Exo', sans-serif;
  /* margin-bottom: 0.7em; */
  line-height: 1.1;
}

.introJob {
  font-size: 50px;
  font-family: 'Exo', sans-serif;
  color: #a1bee5;
  line-height: 1.1;
}

.introTitle {
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.introTitleWrapper {
  height: 100%;
  width: 280px;
}

.introTitleItem {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #3d79c9;
  font-family: 'Exo', sans-serif;
}

.introTitleDescription {
  visibility: hidden;
  font-style: italic;
  font-weight: 400;
}

.introImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-bottom-left-radius: 10px 10px;
}

.introBackground {
  clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  background-color: #a1bee5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

/* .buttonContainer{
  display:flex;
  flex-wrap: wrap;
  justify-content:flex-end;
  
} */


.introButton:hover {
  background-color:#fafafa;
}

@media only screen and (min-width: 992px) {
  .introTitleDescription {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .introContainer {
    height: 20vh;
    /* margin-bottom:50px; */
  }
}

@media only screen and (min-width: 768px) {
  .introTitleDescription {
    color: #a1bee5;
    font-size: 20px;
    line-height: 1.5;
    visibility: visible;
    width: 70%;
    font-weight: 400;
  }
  .introContainer {
    height: 30vh;
  }
}

@media only screen and (max-width: 479px){
  .introContainer{
    margin-bottom:100px;
  }
}


