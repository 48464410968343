.container {
  /* text-align: center; */
}

.text {
  font-weight: 550;
  overflow-wrap: break-word;
  max-width: 800px;
  margin: 0 auto;
}

.successImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  height: auto;
  width: 100%;
}

.heading{
  display: flex;
  align-items: center;
  justify-content: center;
  

  position: absolute;
  top: 10%;
  left: 49.5%; 
  transform: translate(-50%, -50%);
  color: white;
  border-style: solid;
  padding: 10px;
  margin-top: 40px;
  margin-bottom:0px;
  
}